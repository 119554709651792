<template>
  <a-modal
    :visible="visible"
    :title="form.id ? '编辑' : '新增'"
    width="640px"
    :mask-closable="false"
    @ok="handleOk"
    @cancel="handleCancel"
    centered
    :destroyOnClose="true"
  >
    <a-form
      ref="formRef"
      :model="form"
      :rules="rules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-item label="邀请码名称" name="invitationCodeName">
        <a-input v-model:value="form.invitationCodeName" placeholder="请输入邀请码名称" />
      </a-form-item>
      <a-form-item label="有效期" name="value2">
        <a-range-picker
          v-model:value="form.value2"
          :disabled-date="disabledDate"
          valueFormat="YYYY-MM-DD HH:mm:ss"
          @change="handleData"
        />
      </a-form-item>
      <a-form-item label="授权用户" name="value3">
        <a-input
          v-model:value="form.value3"
          placeholder="请添加授权用户"
          class="inputMaskItem"
          :disabled="form.id"
        >
          <template #suffix>
            <PlusOutlined v-if="!form.id"/>
          </template>
        </a-input>
        <div class="inputMask" @click.stop="openTable"></div>
      </a-form-item>
      <a-form-item label="邀请码权限" class="inviteBefore">
        <a-checkbox v-model:checked="form.sendGoldStatus">
          完成注册双方金币奖励
        </a-checkbox>
      </a-form-item>
      <a-form-item label=" " class="emptyItem">
<!--          <a-select-->
<!--              v-model:value="form.sendGoldPackageCode"-->
<!--              placeholder="请选择完成注册双方金币奖励"-->
<!--              @change="handleChangeOne(form.sendGoldPackageCode, 'sendGoldStatus')"  -->
<!--          >-->
<!--            <a-select-option-->
<!--                v-for="item in options5"-->
<!--                :key="item.id"-->
<!--                :value="item.packageCode"-->
<!--            >-->
<!--              {{ item.packageName }}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
        <div class="sendInviterGoldNum">
            <a-input-number
                v-model:value="form.sendGoldNum"
                placeholder="请选择完成注册双方金币奖励"
                style="width: 100%"
                :min="0"
                @change="handleChangeOne(form.sendGoldNum, 'sendGoldStatus')"
                @blur="handleBlurDtaeOne"
            />
            <span class="ml-8">个</span>
        </div>
      </a-form-item>
      <a-form-item label=" " class="emptyItem mt--8">
        <a-checkbox v-model:checked="form.sendFreeDurationStatus">
          被邀请者免费使用时长
        </a-checkbox>
      </a-form-item>
      <a-form-item label=" " class="emptyItem">
<!--        <a-select-->
<!--            v-model:value="form.sendFreeDurationPackageCode"-->
<!--            placeholder="请选择被邀请者免费使用时长"-->
<!--            @change="handleChangeOne(form.sendFreeDurationPackageCode, 'sendFreeDurationStatus')"-->
<!--        >-->
<!--          <a-select-option-->
<!--              v-for="item in options7"-->
<!--              :key="item.id"-->
<!--              :value="item.packageCode"-->
<!--          >-->
<!--            {{ item.packageName }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
        <div class="sendInviterGoldNum">
            <a-input-number
                v-model:value="form.sendFreeDurationMonth"
                placeholder="请选择被邀请者免费使用时长"
                style="width: 90%"
                :min="0"
                @change="handleChangeOne(form.sendFreeDurationMonth, 'sendFreeDurationStatus')"
                @blur="handleBlurDtaeT"
            />
            <span class="ml-8">小时</span>
        </div>

      </a-form-item>
      <a-form-item label=" " class="emptyItem mt--8">
        <a-checkbox v-model:checked="form.sendInviterGoldStatus">
          被邀请者激活金币奖励
        </a-checkbox>
      </a-form-item>
      <a-form-item label=" " class="emptyItem">
<!--        <a-select-->
<!--            v-model:value="form.sendInviterGoldPackageCode"-->
<!--            placeholder="请选择完成激活金币奖励"-->
<!--            @change="handleChangeOne(form.sendInviterGoldPackageCode, 'sendInviterGoldStatus')"-->
<!--        >-->
<!--          <a-select-option-->
<!--              v-for="item in options9"-->
<!--              :key="item.id"-->
<!--              :value="item.packageCode"-->
<!--          >-->
<!--            {{ item.packageName }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
        <div class="sendInviterGoldNum">
          <a-input-number
              v-model:value="form.sendInviterGoldNum"
              placeholder="请选择完成激活金币奖励"
              style="width: 100%"
              :min="0"
              @change="handleChangeOne(form.sendInviterGoldNum, 'sendInviterGoldStatus')"
              @blur="handleBlurDtaeF"
          />
          <span class="ml-8">个</span>
        </div>


      </a-form-item>
      <a-form-item label="启用状态" class="emptyItem">
        <a-switch
            :checked="form.status"
            checked-children="启用"
            un-checked-children="禁用"
            @click="handleClickSwitch(form.status)"
        />
      </a-form-item>


    </a-form>
  </a-modal>
  <a-modal
    :visible="tableVisible"
    title="选择授权用户"
    width="800px"
    :mask-closable="false"
    @ok="tableHandleOk"
    @cancel="tableHandleCancel"
    :destroyOnClose="true"
    centered
  >
    <div class="iss-search-wrap">
      <search-form ref="searchFormRef" :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        :allow-selection="{
          type: 'radio',
        }"
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ y: 400 }"
      >
        <template #sex="{ record }">
          <div v-if="record.sex === '1'">男</div>
          <div v-if="record.sex === '0'">女</div>
        </template>
      </grid>
    </div>
  </a-modal>
</template>

<script>
import { reactive, ref, toRefs, toRaw, watch } from 'vue';
// Select,
import {Form, DatePicker, Checkbox, message, Switch, InputNumber } from 'ant-design-vue';
import marketingApi from '@/api/marketing.js';
import { PlusOutlined } from '@ant-design/icons-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import userApi from "@/api/user";
import moment from 'moment';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    // ASelect: Select,
    // ASelectOption: Select.Option,
    ACheckbox: Checkbox,
    ARangePicker: DatePicker.RangePicker,
    PlusOutlined,
    SearchForm,
    Grid,
    ASwitch: Switch,
    AInputNumber: InputNumber,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const searchFormRef = ref();
    const gridRef = ref();
    const formRef = ref();
    const states = reactive({
      tableVisible: false,
      // options5: [],
      // options7: [],
      // options9: [],
      search: {},
      code1: '',
      code2: '',
      code3: '',

    });
    const form = reactive({
      id: null,
      status: true,
      value3: '',
      invitationCodeName: '',
      beginTime: '',
      endTime: '',
      value2: [],
      userId: '',
      sendGoldStatus: false,
      // 完成注册双方金币奖励
      sendGoldPackageCode: null,
      sendFreeDurationStatus: false,
      //  被邀请者免费使用时长
      sendFreeDurationPackageCode: null,
      sendInviterGoldStatus: false,
      // 被邀请者激活金币奖励
      sendInviterGoldPackageCode: null,

      //   完成注册双方金币奖励
      sendGoldNum: '',
      // 被邀请者激活金币奖励
      sendInviterGoldNum: '',
      // 被邀请者免费使用时长
      sendFreeDurationMonth: '',



    });
    const required = { required: true, message: '不能为空' };
    const rules = reactive({
      invitationCodeName: [required],
      value2: [{ ...required, type: 'array' }],
      value3: [required],
    });

    const openTable = () => {
      if (!form.id) {
        states.tableVisible = true;
      }

    };
    watch(
      () => props.visible,
        (value) => {
        if (value) {
          console.log('value', value)
         Object.assign(form, {
           id: null,
           value3: '',
           invitationCodeName: '',
           beginTime: '',
           endTime: '',
           value2: [],
           userId: '',
           sendGoldStatus: false,
           sendGoldPackageCode: '',
           sendFreeDurationStatus: null,
           sendFreeDurationPackageCode: '',
           sendInviterGoldStatus: false,
           sendInviterGoldPackageCode: '',
         })

          // 邀请码权限数据
          marketingApi.getPackageInfoTypeDetailList('', {
            types: [3],
            typeDetails: [1]
          }).then(res => {
            if (res) {
              // console.log('res', res)
              // states.options5 = res
              form.sendGoldPackageCode = res.packageCode
              states.code1 = res.packageCode
            }
          });
          marketingApi.getPackageInfoTypeDetailList('', {
            types: [3],
            typeDetails: [2]
          }).then(res => {
            if (res) {
              // console.log('res 邀请码权限数据', res)
              // states.options7 = res
              form.sendFreeDurationPackageCode = res.packageCode
              states.code2 = res.packageCode
            }
          });
          marketingApi.getPackageInfoTypeDetailList('', {
            types: [3],
            typeDetails: [3]
          }).then(res => {
            if (res) {
              // console.log('res getPackageInfoTypeDetailList', res)
              // states.options9 = res
              form.sendInviterGoldPackageCode = res.packageCode
              states.code3 = res.packageCode
            }
          });

          // 编辑数据
          if (props.initValue?.id) {
            marketingApi.userInvitation('resources:delete',props.initValue.id).then((res) => {
              if (res) {
                // console.log('编辑数据', res)
                Object.assign(form, res);
                form.id = res.id;
                form.value2.push(res.beginTime)
                form.value2.push(res.endTime)
                form.sendGoldPackageCode = res.sendGoldPackageCode
                form.sendFreeDurationPackageCode = res.sendFreeDurationPackageCode
                form.sendInviterGoldPackageCode = res.sendInviterGoldPackageCode

              }

            })


            // 编辑时，查询用户信息 回填授权用户
            // 根据用户Id 查询用户详情数据
            userApi.getUserInfoById('', props.initValue.userId).then(res => {
              if (res) {
                form.value3 = res.userName
              }
            });

          }

        }

      }
    );

    watch(
        ()=>form.sendGoldStatus,
        (value)=>{
          if (value) {
            form.sendGoldPackageCode = 'MANAGE_FINISH_ORDER'
          } else {
            form.sendGoldPackageCode = null
          }
        }
    )

    watch(
        ()=>form.sendFreeDurationStatus,
        (value)=>{
          if (value) {
            form.sendFreeDurationPackageCode = 'MANAGE_INVITATION_TIME'
          } else {
            form.sendFreeDurationPackageCode = null
          }
        }
    )

    watch(
        ()=>form.sendInviterGoldStatus,
        (value)=>{
          if (value) {
            form.sendInviterGoldPackageCode = 'MANAGE_INVITEE'
          } else {
            form.sendInviterGoldPackageCode = null
          }
        }
    )


    return {
      searchFormRef,
      gridRef,
      form,
      rules,
      formRef,
      ...toRefs(states),
      handleCancel: () => {
        context.emit('update:visible', false)
        // 表单重置
        formRef.value.resetFields();
      },
      handleOk: () => {
        formRef.value.validate().then(() => {
          // console.log('请配置邀请码权限', form.sendGoldPackageCode ,form.sendFreeDurationPackageCode, form.sendInviterGoldPackageCode )
          if (form.sendGoldPackageCode || form.sendFreeDurationPackageCode || form.sendInviterGoldPackageCode) {
            let type = 'addInvit';
            form.id && (type = 'updateInvit');
            marketingApi[type]('', toRaw(form)).then(() => {
              message.success('操作成功');
              context.emit('fnOk', false);
              form.sendGoldNum = ''
              form.sendFreeDurationMonth = ''
              form.sendInviterGoldNum = ''
              formRef.value.resetFields();
            });

          } else {
            return message.error('请配置邀请码权限')
          }

        });
      },
      tableHandleCancel: () => {
        states.tableVisible = false
        states.search = {};
      },
      tableHandleOk: () => {
        const getObj = gridRef.value.getRowSelection();
        form.userId = getObj.id
        form.value3 = getObj.userName
        states.tableVisible = false;
      },
      handleClickSwitch(value) {
        form.status = !value
      },

      openTable,
      url: marketingApi.getInviterUserPage,
      items: [{ key: 'condition', label: '昵称或手机号' }],
      columns: [
        { dataIndex: 'userName', title: '昵称', width: 120, ellipsis: true },
        {
          dataIndex: 'sex',
          title: '性别',
          width: 60,
          ellipsis: true,
          slots: { customRender: 'sex' },
        },
        {
          dataIndex: 'mobile',
          title: '手机号',
          width: 120,
          ellipsis: true,
        },
        { dataIndex: 'email', title: '邮箱', width: 120, ellipsis: true },
      ],
      handleData: (value) =>{
        form.beginTime = value[0]
        form.endTime = value[1]
      },
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        states.search = temp;
      },
      disabledDate: current => {
        return current && current < moment().subtract(1, 'days').endOf('day');
      },
      handleChangeOne: (value, type) => {
        if (value) {
          form[type] = true
        }
      },
      handleBlurDtaeOne: () => {
        let num = form.sendGoldNum;
        if (num % 1 !== 0) {
          form.sendGoldNum = '';
          // form.sendGoldStatus = false
          return message.error('请输入整正数');
        }
      },
      handleBlurDtaeT: () => {
        let num = form.sendFreeDurationMonth;
        if (num % 1 !== 0) {
          form.sendFreeDurationMonth = '';
          // form.sendFreeDurationStatus = false
          return message.error('请输入整正数');
        }
      },
      handleBlurDtaeF: () => {
        let num = form.sendInviterGoldNum;
        if (num % 1 !== 0) {
          form.sendInviterGoldNum = '';
          // form.sendInviterGoldStatus = false
          return message.error('请输入整正数');
        }
      },

    };
  },
};
</script>
<style lang="less" scoped>
.a-no-active {
  color: #86909c;
  border: none;
}
.a-active {
  border-bottom: 1px solid @primary-color;
}
.emptyItem {
  margin-top: -18px;
  :deep(& .ant-form-item-label > label::after) {
    content: ' ';
  }
}
.mt--8 {
  margin-top: -8px;
}
.inputMaskItem {
  position: relative;
  z-index: 1;
}
.inputMask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
}
.inviteBefore::before{
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  position: absolute;
  top: 256px;
  left: 53px;
}
.sendInviterGoldNum {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
